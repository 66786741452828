<template lang="">
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <b-row class="row mt-2">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <h4><i class="fa fa-solid ri-user-star-fill primary-color mr-2" aria-hidden="true"></i>&nbsp;
                  {{`${orgData.org_name} ${cvCardTitle}`}}&nbsp;
                  <i title="FAQ" class="ri-question-line pointer primary-color" style="font-size: 25px;" @click="gotoFAQList()"></i><br>
                  <small class="ml-4"><b-badge style="color: #FFF;">
                    {{whereFilter.dateRange.startDate|dateFormatDDMMYYYY}} to {{whereFilter.dateRange.endDate|dateFormatDDMMYYYY}}
                  </b-badge></small>
                </h4><br>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="head_buttons pull-right">
                  <b-btn variant="primary" class="mr-2 mb-2" @click="goToDashboard" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">Dashboard</b-btn>
                  <b-btn variant="primary" class="mr-2 mb-2" @click="goToRedemptionDashboard">Redemption</b-btn>
                </div>
              </b-col>
            </b-row>
          </template>
          <template>
            <div class="row p-0 mb-4">
              <!-- Total Revenue Done -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#994499">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Total Revenue<br>(INR)<br>
                      <b>{{orgAnalyticsObj.user_revenue_inr.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else  >
                      Total Revenue<br>(USD)<br>
                      <b>{{orgAnalyticsObj.user_revenue_usd.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Total Revenue Done -->

              <!-- Total Commission Earned -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#3366CC">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Total Commission<br>(INR)<br>
                      <b>{{orgAnalyticsObj.user_commission_inr.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else>
                      Total Commission<br>(USD)<br>
                      <b>{{orgAnalyticsObj.user_commission_usd.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Total Commission Earned -->

              <!-- Revenue Within Daterange -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#994499">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Revenue<br>(INR)<br>
                      <b>{{orgAnalyticsObj.user_revenue_inr_daterange.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else>
                      Revenue<br>(USD)<br>
                      <b>{{orgAnalyticsObj.user_revenue_usd_daterange.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Revenue Within Daterange -->

              <!-- Commission Within Daterange -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#3366CC">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Commission<br>(INR)<br>
                      <b>{{orgAnalyticsObj.user_commission_inr_daterange.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else>
                      Commission<br>(USD)<br>
                      <b>{{orgAnalyticsObj.user_commission_usd_daterange.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Commission Within Daterange -->
            </div>
          </template>
          <template>
            <b-row class="mb-2">
              <!-- Backend Search -->
              <b-col class="col-12 col-sm-7 col-md-6 col-lg-6">
                <input class="form-control mb-2"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText"
                />
              </b-col><!-- Backend Search -->

              <b-col class="col-12 col-sm-5 col-md-4 col-lg-4">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" v-model="whereFilter.dateRange" @update="orgSaasRevenueDashboardListDateWise()"></date-range-picker>
                </div>
              </b-col>
              <b-col class="col-12 col-sm-12 col-md-2 col-lg-2">
                <div class="pull-right">
                  <b-btn variant="primary" class="mr-2 mb-2" @click="backendCsvDownload">CSV</b-btn>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="orgSaasRevenueReportObjList && orgSaasRevenueReportObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="orgSaasRevenueReportObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage">
                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)"><b>{{ data.item.user_name }}</b></span><br>
                    <span>
                      <small>
                        Id:&nbsp;{{data.item.user_id}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                        Email:&nbsp;{{data.item.user_email}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Email" @click="doCopy(data.item.user_email)"></i><br>
                        Mobile:&nbsp;{{data.item.user_mobile}}<br>
                        Country:&nbsp;{{data.item.user_country}}<br>
                      </small>
                    </span>
                  </template>

                  <!-- User Product Name -->
                  <template v-slot:cell(user_product_name)="data">
                    <div>
                      <b-badge :style="getStateColorCode()" class="mb-2">
                        {{data.item.user_product_name.length > 0 ? data.item.user_product_name : "Student Club"}}
                      </b-badge><br>
                      <small>
                        Product Id:&nbsp;{{data.item.user_product_subs_plan_id}}<br>
                        Purchase Date:&nbsp;{{data.item.user_product_purchase_date}}
                      </small>
                    </div>
                  </template><!-- User Product Name -->

                  <!-- User Commission INR -->
                  <template v-if="userData.user_country == 99" v-slot:cell(user_commision_inr)="data">
                    <div class="pointer">
                      {{data.item.user_commision_inr.toLocaleString()}}<br/>
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)" class="mb-2" @click="goToRedemptionDashboard">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge><br>
                      <!-- <small v-if="data.item.redemption_bank_tid">Payout Txn Id:&nbsp;{{data.item.redemption_bank_tid.substring(0,5)}}...&nbsp;<i class="fa-solid fa-copy pointer" title="Copy Payout Txn Id" @click="doCopy(data.item.redemption_bank_tid)"></i></small> -->
                    </div>
                  </template><!-- User Commission INR -->

                  <!-- User Commission USD -->
                  <template v-else v-slot:cell(user_commision_usd)="data">
                    <div class="pointer">
                      {{data.item.user_commision_usd.toLocaleString()}}<br/>
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)" class="mb-2" @click="goToRedemptionDashboard">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge><br>
                      <!-- <small v-if="data.item.redemption_bank_tid">Payout Txn Id:&nbsp;{{data.item.redemption_bank_tid.substring(0,5)}}...&nbsp;<i class="fa-solid fa-copy pointer" title="Copy Payout Txn Id" @click="doCopy(data.item.redemption_bank_tid)"></i></small> -->
                    </div>
                  </template><!-- User Commission USD -->

                  <!-- User Revenue INR -->
                  <template v-if="userData.user_country == 99" v-slot:cell(user_revenue_inr)="data">
                    <div class="pointer">
                      {{data.item.user_revenue_inr.toLocaleString()}}<br/>
                      <!-- <small v-if="data.item.user_pay_tid">Payment Id:&nbsp;{{data.item.user_pay_tid.substring(0,5)}}...&nbsp;<i class="fa-solid fa-copy pointer" title="Copy Payment Id" @click="doCopy(data.item.user_pay_tid)"></i></small> -->
                    </div>
                  </template><!-- User Revenue INR -->

                  <!-- User Revenue USD -->
                  <template v-else v-slot:cell(user_revenue_usd)="data">
                    <div class="pointer">
                      {{data.item.user_revenue_usd.toLocaleString()}}<br/>
                      <!-- <small v-if="data.item.user_pay_tid">Payment Id:&nbsp;{{data.item.user_pay_tid.substring(0,5)}}...&nbsp;<i class="fa-solid fa-copy pointer" title="Copy Payment Id" @click="doCopy(data.item.user_pay_tid)"></i></small> -->
                    </div>
                  </template><!-- User Revenue USD -->
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                <!-- No Data Found -->
                Kindly change your calendar dates or it may be possible that no revenue has been generated yet on your part
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" class="mb-2" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"
import { OrgSaas } from "../../../FackApi/api/orgSaas"
import ColorCodes from "../../../Utils/colorcodes.js"
import userPermission from "../../../Utils/user_permission.js"
import RedemptionStatusJson from "../../../FackApi/json/RedemptionStatus.json"

export default {
  name: "OrgSaasRevenueDashboard",
  components: {},
  data () {
    return {
      apiName: "orgsaas_revenue_report",
      cvCardTitle: "Revenue Dashboard",
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        search_param: ""
      },
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Revenue Dashboard",
      showToast: false,
      orgId: null,
      orgSaasRevenueReportObjList: [],
      orgSaasRevenueAnalyticsObj: null,
      showLoadMoreBtn: true,
      totalRows: 1,
      cvSearchText: "Type to search...",
      dataPerPage: 100,
      currentBatchNo: 1,
      orgData: {},
      sortBy: "",
      sortDesc: false,
      filter: null,
      filterOn: [],
      orgAnalyticsObj: {
        user_revenue_inr: 0.00,
        user_revenue_usd: 0.00,
        user_commission_inr: 0.00,
        user_commission_usd: 0.00,
        user_revenue_inr_daterange: 0.00,
        user_revenue_usd_daterange: 0.00,
        user_commission_inr_daterange: 0.00,
        user_commission_usd_daterange: 0.00
      },
      redemptionStatusStringObj: RedemptionStatusJson,
      payStatusStringObj: {
        "PGW_CAPTURED": "Payment success",
        "PGW_SUCCESS": "Payment success",
        "PGW_REFUNDED": "Payment Refunded"
      },
      saasOrgDataObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role.
    if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11116" || this.userData.user_role == "USERROLE11118")) {
      this.$router.back()
    }

    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    await this.getFiltersFromCache()

    if (this.$route.query.org_id) {
      if ((this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
        this.orgId = this.$route.query.org_id
      }
    }
    else if (!this.saasOrgData || this.saasOrgData.org_admin_email != this.userData.user_email) {
      // orgadmin visits GIDE from the platform's login page4
      const otherOrgId = Object.keys(this.userData.modules.organisations)
      if (otherOrgId && this.userData && this.userData.roles[otherOrgId].udr_name) {
        this.orgId = otherOrgId
      }
    }
    else {
      // orgadmin visits GIDE from his/her own login page
      this.orgId = this.saasOrgData.org_id
      this.orgData = this.saasOrgData
    }

    await this.getOrgDetails()

    // Based on user's country, decide which currency is to be displayed
    if (this.userData.user_country == 99) {
      this.columns = [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Student Details", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Product Details", key: "user_product_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Revenue (INR)", key: "user_revenue_inr", class: "text-left align-text-top", sortable: true },
        { label: "Commission (INR)", key: "user_commision_inr", class: "text-left align-text-top", sortable: true }
      ]
    }
    else {
      this.columns = [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Student Details", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Product Details", key: "user_product_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Revenue (USD)", key: "user_revenue_usd", class: "text-left align-text-top", sortable: true },
        { label: "Commission (USD)", key: "user_commision_usd", class: "text-left align-text-top", sortable: true }
      ]
    }

    this.getRevenueReport()
    this.getRevenueAnalytics()
  },
  methods: {
    /**
     * getBgFor
    */
    getBgFor (redemptionStatus) {
      switch (redemptionStatus) {
        case "TOBEOTPVERF":
          return ColorCodes.getBgColor(50) // blue
        case "TOBEAPPROVED":
          return ColorCodes.getBgColor(15) // dark orange
        case "NOTREDEEMED":
          return ColorCodes.getBgColor(6) // dark skyblue
        case "TOBEPAID":
          return ColorCodes.getBgColor(17) // dark green
        case "PAID":
          return ColorCodes.getBgColor(3) // green
        case "TOBERECOVERED":
          return ColorCodes.getBgColor(9) // red
        case "DEDUCTED":
          return ColorCodes.getBgColor(30) // blue
        case "PGW_CAPTURED":
          return ColorCodes.getBgColor(32) // Teal Green
        case "PGW_SUCCESS":
          return ColorCodes.getBgColor(32) // Teal Green
        case "PGW_REFUNDED":
          return ColorCodes.getBgColor(15) // Red with orange tint
        case "NOT_ELIGIBLE":
        default:
          return ColorCodes.getBgColor(4)
      }
    },
    /**
      * get data from store cache by this method
      */
    async getFiltersFromCache () {
      await this.getDateFilter()
    },
    /**
     * goTo Redemption Dashboard
     */
    goToRedemptionDashboard () {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        this.$router.push(`/redemption_dashboard?org_id=${this.saasOrgData && this.saasOrgData.org_id ? this.saasOrgData.org_id : this.orgData.org_id}`)
      }
      else {
        this.$router.push(`/redemption_dashboard`)
      }
    },
    /**
     * goto main dashboard
     */
    goToDashboard () {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        this.$router.push(`/dashboard?org_id=${this.saasOrgData && this.saasOrgData.org_id ? this.saasOrgData.org_id : this.orgData.org_id}`)
      }
      else {
        this.$router.push(`/dashboard`)
      }
    },
    /**
     * getRevenueReport
     */
    async getRevenueReport (loadViaLoadMore = false, downloadCsv = false) {
      try {
        const payload = {
          org_id: this.orgId,
          org_admin_email: this.orgData.org_admin_email
        }
        payload.currentBatchNo = this.currentBatchNo
        payload.dataPerPage = this.dataPerPage
        payload.downloadCsv = downloadCsv

        const revenueReportResp = await OrgSaas.orgsaasRevenueReport(this, payload, this.whereFilter)
        if (!revenueReportResp.resp_status) {
          if (this.currentBatchNo > 1) {
            // anything less than 1 (which is the first page) becomes an invalid prop for the b-pagination component
            this.currentBatchNo--
          }
          this.showLoadMoreBtn = false
          return
        }

        if (downloadCsv) {
          window.open(revenueReportResp.resp_csv_file_url)
          return
        }

        if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
          this.orgSaasRevenueReportObjList = [ ...revenueReportResp.resp_data.data ]
        }
        else {
          this.orgSaasRevenueReportObjList = [ ...this.orgSaasRevenueReportObjList, ...revenueReportResp.resp_data.data ]
        }

        this.totalRows = this.orgSaasRevenueReportObjList.length
      }
      catch (err) {
        console.error("Exception in getRevenueReport () and err: ", err.message)
      }
    },
    /**
     * getRevenueAnalytics
     */
    async getRevenueAnalytics () {
      try {
        const payload = {
          org_admin_email: this.orgData.org_admin_email,
          org_id: this.orgId
        }
        const revenueAnalyticsResp = await OrgSaas.orgsaasRevenueAnalytics(this, payload, this.whereFilter)
        if (!revenueAnalyticsResp.resp_status) {
          return
        }
        this.orgAnalyticsObj = { ...this.orgAnalyticsObj, ...revenueAnalyticsResp.resp_data.data }
      }
      catch (err) {
        console.error("Exception in getRevenueAnalytics() and err: ", err.message)
      }
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      this.showLoadMoreBtn = false
      this.currentBatchNo = 1
      await this.getRevenueReport()
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.getRevenueReport(false, true)
    },
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgDomain = window.location.hostname
        const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
        if (!orgDetails.resp_status) {
          return
        }
        this.orgData = orgDetails.resp_data.data

        // set the org data in local storage for saving in user table
        this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.getRevenueReport(true)
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * goToFaqs
     */
    goToFaqs () {
      console.error("FAQ Path not defined")
    },

    orgSaasRevenueDashboardListDateWise () {
      this.setDateFilter()
      this.getRevenueReport()
      this.getRevenueAnalytics()
    },
    /**
      * Setting Date Range in the LS
      */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /*
      * get Date Filters From Store
      */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = storeDate.dateRange
      }
    },
    /**
     * getStateColorCode
     */
    getStateColorCode () {
      // `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
    },
    /**
     * goto FAQList
     */
    gotoFAQList () {
      this.$router.push(`/faq_list?gidepreneur`)
    }
  }
}
</script>
<style>
  .cardBoxs{
    align-content: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    min-height: 130px;
  }
</style>
